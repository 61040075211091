@import url('https://fonts.googleapis.com/css?family=Lato:300,500,700,900');


.light {
  font-weight: 300;
  font-style: italic;

}
.regular {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
.black {
  font-weight: 900;
}

.App {
  font-family: 'Lato';
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-Coming-Soon{
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F4D076;

}

.App-Social{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: calc(50px + 2vmin);
}

.Social-Link{
  color: #49543D;
  font-size: calc(5px + 2vmin);
  margin-right: 20px;
  margin-left: 20px;
}

.Social-Image{
  height: 5vmin;
}

p{
  color: #49543D;
  margin-bottom: 10px;
  margin-top: 0;

}
